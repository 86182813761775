import { PageEvent } from '@angular/material/paginator';
import { Action, createReducer, on } from '@ngrx/store';
import { ActiveBookingAggregate } from 'src/app/shared/models/booking.model';
import { AccountUser } from '../../../../../../src/app/shared/models/account-user.model';
import { Driver, DriverAggregate, DriverState } from '../../shared/models/driver.model';
import { FirebaseUser } from '../../shared/models/firebase-user.model';
import { MaxEtaSchedule } from '../../shared/models/max-eta-schedule.model';
import { PlatformSetting } from '../../shared/models/platform-setting.model';
import { PricingFee } from '../../shared/models/pricing-fee.model';
import { ProductFeature } from '../../shared/models/product-feature.model';
import { ProductPackage } from '../../shared/models/product-package.model';
import { VehicleAggregate } from '../../shared/models/vehicle.model';
import { DashboardActions } from './index';

export interface SupportAgentDashboardState {
  isLoading: boolean;
  isLoadedProductPackages: boolean;
  activeTabId: string;
  drivers: DriverAggregate[];
  driverStates: DriverState[];
  vehicles: VehicleAggregate[];
  bookings: ActiveBookingAggregate[];
  platformSettings: PlatformSetting[];
  productPackages: ProductPackage[];
  maxEtaSchedules: MaxEtaSchedule[];
  packagesLog: ProductPackage[];
  featuresLog: ProductFeature[];
  featureFeeLog: PricingFee[];
  driversLog: Driver[];
  platformSettingLog: PlatformSetting[];
  maxEtaScheduleLog: MaxEtaSchedule[];
  accountUsers: AccountUser[];
  firebaseUsers: FirebaseUser[];
  userLogs: AccountUser[];
  page: PageEvent;
}

export const initialState: SupportAgentDashboardState = {
  isLoading: false,
  isLoadedProductPackages: false,
  activeTabId: null,
  drivers: [],
  driverStates: [],
  vehicles: [],
  bookings: [],
  platformSettings: [],
  productPackages: [],
  maxEtaSchedules: [],
  packagesLog: [],
  featuresLog: [],
  featureFeeLog: [],
  driversLog: [],
  platformSettingLog: [],
  maxEtaScheduleLog: [],
  accountUsers: [],
  firebaseUsers: [],
  userLogs: [],
  page: {
    pageIndex: 0,
    pageSize: 10,
    previousPageIndex: 0,
    length: 1,
  },
};

const dashboardReducer = createReducer(
  initialState,
  on(DashboardActions.dashboardComponentDestroyed, (state, _action) => ({
    ...state,
    activeTabId: null,
    drivers: [],
    driverStates: [],
    vehicles: [],
    bookings: [],
    platformSettings: [],
    productPackages: [],
    maxEtaSchedules: [],
    packagesLog: [],
    featuresLog: [],
    featureFeeLog: [],
    driversLog: [],
    driverFirebaseUserLog: [],
    platformSettingLog: [],
    maxEtaScheduleLog: [],
    accountUsers: [],
    firebaseUsers: [],
    userLogs: [],
    page: {
      pageIndex: 0,
      pageSize: 10,
      previousPageIndex: 0,
      length: 1,
    },
    isLoadedProductPackages: false,
  })),
  on(DashboardActions.dashboardTabChanged, (state, action) => ({
    ...state,
    activeTabId: action.tabId,
  })),
  on(DashboardActions.driversFetched, (state, action) => ({
    ...state,
    drivers: action.drivers,
  })),
  on(DashboardActions.driverStatesFetched, (state, action) => ({
    ...state,
    driverStates: action.states,
  })),
  on(DashboardActions.vehiclesFetched, (state, action) => ({
    ...state,
    vehicles: action.vehicles,
  })),
  on(DashboardActions.activeBookingsFetched, (state, action) => ({
    ...state,
    bookings: action.bookings,
  })),
  on(DashboardActions.productPackagesFetched, (state, action) => ({
    ...state,
    productPackages: action.productPackages,
    isLoadedProductPackages: true,
  })),
  on(DashboardActions.platformSettingsFetched, (state, action) => ({
    ...state,
    platformSettings: action.platformSettings,
  })),
  on(DashboardActions.maxEtaSchedulesFetched, (state, action) => ({
    ...state,
    maxEtaSchedules: action.maxEtaSchedules,
  })),
  on(DashboardActions.productPackageLogFetched, (state, action) => ({
    ...state,
    packagesLog: action.packages,
  })),
  on(DashboardActions.productFeatureLogFetched, (state, action) => ({
    ...state,
    featuresLog: action.features,
  })),
  on(DashboardActions.productFeatureFeeLogFetched, (state, action) => ({
    ...state,
    featureFeeLog: action.fees,
  })),
  on(DashboardActions.driverLogFetched, (state, action) => ({
    ...state,
    driversLog: action.drivers,
  })),
  on(DashboardActions.maxEtaScheduleLogFetched, (state, action) => ({
    ...state,
    maxEtaScheduleLog: action.maxEtaSchedules,
  })),
  on(DashboardActions.platformSettingLogFetched, (state, action) => ({
    ...state,
    platformSettingLog: action.platformSettings,
  })),
  on(DashboardActions.firebaseUsersFetched, (state, action) => ({
    ...state,
    firebaseUsers: action.firebaseUsers,
  })),
  on(DashboardActions.accountUsersFetched, (state, action) => ({
    ...state,
    accountUsers: action.users,
  })),
  on(DashboardActions.accountUserLogFetched, (state, action) => ({
    ...state,
    userLogs: action.users,
  })),
  on(DashboardActions.pageChanged, (state, action) => ({
    ...state,
    page: action.pageEvent,
  })),
  on(DashboardActions.productPackagesComponentInitialized, (state, _action) => ({
    ...state,
    isLoadedProductPackages: false,
  })),
);

export function reducer(state: SupportAgentDashboardState, action: Action) {
  return dashboardReducer(state, action);
}
