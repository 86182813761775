import { Money } from '../../../../../../src/app/shared/models/money.model';
import { Entity } from './entity.model';

export interface PricingFee extends Entity {
  id: string;
  area_id: string;
  product_id: string;
  product_type: ProductType;
  amount: Money;
}

export enum ProductType {
  UNDEFINED = 0,
  FEATURE = 1,
}
