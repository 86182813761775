import { PageEvent } from '@angular/material/paginator';
import { createAction, props } from '@ngrx/store';
import { ActiveBookingAggregate } from 'src/app/shared/models/booking.model';
import { AccountUser } from '../../../../../../src/app/shared/models/account-user.model';
import { Driver, DriverAggregate, DriverState } from '../../shared/models/driver.model';
import { FirebaseUser } from '../../shared/models/firebase-user.model';
import { MaxEtaSchedule } from '../../shared/models/max-eta-schedule.model';
import { PlatformSetting } from '../../shared/models/platform-setting.model';
import { PricingFee } from '../../shared/models/pricing-fee.model';
import { ProductFeature } from '../../shared/models/product-feature.model';
import { ProductPackage } from '../../shared/models/product-package.model';
import { VehicleAggregate } from '../../shared/models/vehicle.model';

export const dashboardComponentInit = createAction('[Support agent dashboard] Component init');

export const productPackagesComponentInitialized = createAction(
  '[Support agent dashboard] Product packages component initialized',
);

export const dashboardTabChanged = createAction(
  '[Support agent dashboard] Dashboard tab changed',
  props<{ tabId: string }>(),
);

export const dashboardComponentDestroyed = createAction('[Support agent dashboard] Component destroyed');

export const driversFetched = createAction(
  '[Support agent dashboard] Drivers fetched',
  props<{ drivers: DriverAggregate[] }>(),
);

export const driverStatesFetched = createAction(
  '[Support agent dashboard] Driver states fetched',
  props<{ states: DriverState[] }>(),
);

export const vehiclesFetched = createAction(
  '[Support agent dashboard] Vehicles fetched',
  props<{ vehicles: VehicleAggregate[] }>(),
);

export const activeBookingsFetched = createAction(
  '[Support agent dashboard] Active bookings fetched',
  props<{ bookings: ActiveBookingAggregate[] }>(),
);

export const productPackagesFetched = createAction(
  '[Support agent dashboard] Product packages fetched',
  props<{ productPackages: ProductPackage[] }>(),
);

export const platformSettingsFetched = createAction(
  '[Support agent dashboard] Platform settings fetched',
  props<{ platformSettings: PlatformSetting[] }>(),
);

export const maxEtaSchedulesFetched = createAction(
  '[Support agent dashboard] Max ETA schedules fetched',
  props<{ maxEtaSchedules: MaxEtaSchedule[] }>(),
);

export const systemLogComponentInit = createAction('[Support agent dashboard] System log component init');

export const platformSettingLogFetched = createAction(
  '[Support agent dashboard] Platform setting log fetched',
  props<{ platformSettings: PlatformSetting[] }>(),
);

export const maxEtaScheduleLogFetched = createAction(
  '[Support agent dashboard] Max ETA schedule log fetched',
  props<{ maxEtaSchedules: MaxEtaSchedule[] }>(),
);

export const productPackageLogFetched = createAction(
  '[Support agent dashboard] Product package log fetched',
  props<{ packages: ProductPackage[] }>(),
);

export const productFeatureLogFetched = createAction(
  '[Support agent dashboard] Product feature log fetched',
  props<{ features: ProductFeature[] }>(),
);

export const productFeatureFeeLogFetched = createAction(
  '[Support agent dashboard] Product feature fee log fetched',
  props<{ fees: PricingFee[] }>(),
);

export const driverLogFetched = createAction(
  '[Support agent dashboard] Driver log fetched',
  props<{ drivers: Driver[] }>(),
);

export const accountUserLogFetched = createAction(
  '[Support agent dashboard] Account user log fetched',
  props<{ users: AccountUser[] }>(),
);

export const accountUsersFetched = createAction(
  '[Support agent dashboard] Account users fetched',
  props<{ users: AccountUser[] }>(),
);

// TODO refactor after console firebase user migration to account users
export const firebaseUsersFetched = createAction(
  '[Support agent dashboard] Firebase users fetched',
  props<{ firebaseUsers: FirebaseUser[] }>(),
);

export const pageChanged = createAction(
  '[Support agent dashboard] System log page changed',
  props<{ pageEvent: PageEvent }>(),
);
