// noinspection DuplicatedCode

import { createSelector } from '@ngrx/store';
import moment from 'moment';
import { formatDuration } from 'src/app/shared/utils/dateTime.helpers';
import { AuthSelectors } from '../../auth/store';
import { getDriverStateVM } from '../../shared/mappings/mappings';
import { Area } from '../../shared/models/area.model';
import { DashboardTab } from '../../shared/models/dashboard.model.vm';
import { DriverAggregate, DriverState, DriverStateVM } from '../../shared/models/driver.model';
import { MaxEtaSchedule } from '../../shared/models/max-eta-schedule.model';
import { PlatformSetting } from '../../shared/models/platform-setting.model';
import { ProductFeature } from '../../shared/models/product-feature.model';
import { ProductPackage } from '../../shared/models/product-package.model';
import { ProductFeatureVM, ProductPackageListVM } from '../../shared/models/product-package.vm.model';
import { FirestoreSelector } from '../../store/firestore';
import { SupportAgentState } from '../../store/support-agent.state';
import {
  getDriverUsersLog,
  getDriversLog,
  getFeatureFeeLog,
  getFeaturesLog,
  getPackagesLog,
  getPlatformSettingLog,
  mapSystemLogToUsers,
} from './dashboard.mappings';

export const dashboardState = (state: SupportAgentState) => state.supportDashboardState;

export const isUserSignedIn = createSelector(AuthSelectors.signedInSelector, isSignedIn => isSignedIn);

export const userAreaIdsSelector = createSelector(AuthSelectors.userAreaIdsSelector, areaIds => areaIds);

export const dispatchTagsSelector = createSelector(FirestoreSelector.dispatchTagsSelector, tags => tags);

export const areasSelector = createSelector(FirestoreSelector.areaListSelector, areas => areas);

export const productFeatureListSelector = createSelector(
  FirestoreSelector.productFeatureListSelector,
  features => features,
);

export const isLoadingSelector = createSelector(dashboardState, state => state.isLoading);

export const isLoadedProductPackagesSelector = createSelector(dashboardState, state => state.isLoadedProductPackages);

export const activeTabIdSelector = createSelector(dashboardState, state => state.activeTabId);

export const driversSelector = createSelector(dashboardState, state => state.drivers);

export const vehiclesSelector = createSelector(dashboardState, state => state.vehicles);

export const driverStatesSelector = createSelector(dashboardState, state => state.driverStates);

export const activeBookingsSelector = createSelector(dashboardState, state => state.bookings);

export const productPackagesSelector = createSelector(dashboardState, state => state.productPackages);

export const maxEtaSchedulesSelector = createSelector(dashboardState, state => state.maxEtaSchedules);

export const platformSettingsSelector = createSelector(dashboardState, state => state.platformSettings);

export const accountUsersSelector = createSelector(dashboardState, state => state.accountUsers);

// TODO refactor after console firebase user migration to account users
export const firebaseUsersSelector = createSelector(dashboardState, state => state.firebaseUsers);

export const accountUserLogsSelector = createSelector(dashboardState, state => state.userLogs);

export const activeDriversSelector = createSelector(driversSelector, accountUsersSelector, (drivers, users) =>
  users.length > 0 && drivers.length > 0 ? drivers?.filter(d => !users?.find(u => u?.id === d.doc_id)?.disabled) : [],
);

export const tabsSelector = createSelector(userAreaIdsSelector, areasSelector, (ids, areas) =>
  areas.length > 0 ? mapAreasToTabs(areas, ids) : [],
);

function mapAreasToTabs(areas: Area[], ids: string[]): DashboardTab[] {
  if (ids.length > 0) {
    areas = areas.filter(area => ids.includes(area.id));
  }
  return areas.map(area => ({ id: area.id, name: area.name })).sort((a, b) => (a.name < b.name ? -1 : 1));
}

export const productPackagesVMSelector = createSelector(
  productPackagesSelector,
  productFeatureListSelector,
  activeTabIdSelector,
  (packages, features, activeTabId) =>
    packages.length > 0 && features.length > 0 && activeTabId != null
      ? mapProductPackageToProductPackageVM(packages, features, activeTabId)
      : [],
);

export const bookingsSelector = createSelector(
  activeBookingsSelector,
  activeTabIdSelector,
  (activeBookings, activeTabId) =>
    activeBookings.filter(b => b.booking?.pickup_at === null && b.booking?.area_id === activeTabId),
);

export const preBookingsSelector = createSelector(
  activeBookingsSelector,
  activeTabIdSelector,
  (activeBookings, activeTabId) =>
    activeBookings.filter(b => b.booking?.pickup_at !== null && b.booking?.area_id === activeTabId),
);

export const totalActiveBookingsSelector = createSelector(bookingsSelector, bookings => bookings.length);

export const totalActivePreBookingsSelector = createSelector(preBookingsSelector, bookings => bookings.length);

export const driverStatesVMSelector = createSelector(
  driverStatesSelector,
  activeDriversSelector,
  activeTabIdSelector,
  (states, drivers, activeTabId) =>
    states.length > 0 && drivers.length > 0 && activeTabId != null
      ? mapDriversStates(
          states.filter(s => !!s),
          drivers,
          activeTabId,
        )
      : [],
);

function mapDriversStates(states: DriverState[], drivers: DriverAggregate[], activeTabId: string): DriverStateVM[] {
  const areaDriverIds = drivers.filter(driver => driver?.driver?.default_area_id === activeTabId).map(d => d.doc_id);
  return states
    .filter(state => areaDriverIds.includes(state.driver_id))
    .map(state => getDriverStateVM(state.driver_id, state.status?.type, state.job, state.vehicle_id));
}

export const driversIdsSelector = createSelector(activeDriversSelector, divers =>
  divers ? divers.map(driver => driver?.doc_id) : null,
);

export const totalDriversSelector = createSelector(activeDriversSelector, divers => (divers ? divers.length : 0));

export const totalVehiclesSelector = createSelector(vehiclesSelector, activeTabIdSelector, (vehicles, activeTabId) =>
  vehicles.length > 0 && activeTabId !== null
    ? vehicles.filter(v => v.vehicle?.area_ids?.includes(activeTabId)).length
    : 0,
);

export const onlineTotalSelector = createSelector(driverStatesVMSelector, states =>
  states ? states.filter(state => state.status === 'Online').length : 0,
);

export const busyTotalSelector = createSelector(driverStatesVMSelector, states =>
  states ? states.filter(state => state.status === 'Busy').length : 0,
);

export const acceptedTotalSelector = createSelector(driverStatesVMSelector, states =>
  states ? states.filter(state => state.status === 'Accepted').length : 0,
);

export const totalActiveDriversSelector = createSelector(driverStatesVMSelector, states =>
  states ? states.filter(state => state.status !== 'Offline').length : 0,
);

function mapProductPackageToProductPackageVM(
  packages: ProductPackage[],
  features: ProductFeature[],
  activeTabId: string,
): ProductPackageListVM[] {
  return packages
    .filter(p => p.area_id === activeTabId)
    .map(p => ({
      id: p.id,
      icon: p.icon,
      name: p.name,
      shortDescription: p.short_description,
      enabled: p.enabled,
      sortOrder: p.sort_order,
      features: p.feature_ids.length > 0 ? mapFeatures(p.feature_ids, features) : [],
    }))
    .sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1));
}

function mapFeatures(ids: string[], features: ProductFeature[]): ProductFeatureVM[] {
  return ids.map(id => {
    const feature = features.find(f => f.id === id);
    return feature
      ? {
          id: feature.id,
          name: feature.name,
          icon: feature.icon,
        }
      : null;
  });
}

export const maxEtaSelector = createSelector(maxEtaSchedulesSelector, activeTabIdSelector, (schedules, activeTabId) =>
  schedules.length > 0 && activeTabId !== null ? getMaxEta(schedules, activeTabId) : null,
);

// TODO refactor to use date format service
function getMaxEta(schedules: MaxEtaSchedule[], activeTabId: string): number | null {
  const now = moment();
  const format = 'HH:mm:ss';
  const areaSchedule = schedules.find(s => s.area_id === activeTabId);
  const schedule = areaSchedule?.periods?.find(
    p =>
      p.day_of_week === now.isoWeekday() &&
      moment(now, format).isBetween(moment(p.time_period.start, format), moment(p.time_period.finish, format)),
  );
  return schedule != null ? moment.duration(schedule.max_eta, 'seconds').minutes() : null;
}

export const minPrebookingTimeSelector = createSelector(
  platformSettingsSelector,
  activeTabIdSelector,
  (settings, activeTabId) =>
    settings.length > 0
      ? getDurationSettingValue(settings, 'b2b.prebooking_min_duration_to_pickup', activeTabId)
      : null,
);

export const maxPrebookingTimeSelector = createSelector(
  platformSettingsSelector,
  activeTabIdSelector,
  (settings, activeTabId) =>
    settings.length > 0
      ? getDurationSettingValue(settings, 'b2b.prebooking_max_duration_to_pickup', activeTabId)
      : null,
);

function getDurationSettingValue(settings: PlatformSetting[], name: string, areaId: string): string {
  const settingConditions = settings.find(s => s.name === name);
  const defaultCondition = settingConditions.values.find(s => s.condition === null);
  const areaCondition = settingConditions.values.find(
    s => s.condition?.type === 'area_id' && s.condition?.value === areaId,
  );

  if (areaCondition?.duration_value > 0) {
    return formatDuration(Number(areaCondition.duration_value) / 1000);
  }
  return formatDuration(Number(defaultCondition.duration_value) / 1000);
}

// LOGS
export const productFeaturesLogSelector = createSelector(dashboardState, state => state.featuresLog);

export const productFeatureFeeLogSelector = createSelector(dashboardState, state => state.featureFeeLog);

export const productPackagesLogSelector = createSelector(dashboardState, state => state.packagesLog);

export const platformSettingLogSelector = createSelector(dashboardState, state => state.platformSettingLog);

export const driversLogSelector = createSelector(dashboardState, state => state.driversLog);

export const maxEtaScheduleLogSelector = createSelector(dashboardState, state => state.maxEtaScheduleLog);

// Formatted log
export const featuresLogVmSelector = createSelector(productFeaturesLogSelector, features =>
  features.length > 0 ? getFeaturesLog(features) : [],
);

export const featureFeeLogVmSelector = createSelector(
  productFeatureFeeLogSelector,
  activeTabIdSelector,
  productFeatureListSelector,
  areasSelector,
  (fees, activeTabId, features, areas) => (fees.length > 0 ? getFeatureFeeLog(fees, activeTabId, features, areas) : []),
);

export const productPackagesLogVmSelector = createSelector(productPackagesLogSelector, packages =>
  packages.length > 0 ? getPackagesLog(packages) : [],
);

export const platformSettingLogVmSelector = createSelector(platformSettingLogSelector, platformSettings =>
  platformSettings.length > 0 ? getPlatformSettingLog(platformSettings) : [],
);

export const driversLogVmSelector = createSelector(
  driversLogSelector,
  activeTabIdSelector,
  dispatchTagsSelector,
  (driversLog, activeTabId, dispatchTags) => (driversLog ? getDriversLog(driversLog, activeTabId, dispatchTags) : []),
);

export const driverUsersLogVmSelector = createSelector(
  driversSelector,
  activeTabIdSelector,
  accountUserLogsSelector,
  (drivers, activeTabId, accountUserLogs) =>
    drivers.length > 0 && activeTabId && accountUserLogs.length > 0
      ? getDriverUsersLog(drivers, activeTabId, accountUserLogs)
      : [],
);

export const systemLogSortedSelector = createSelector(
  featuresLogVmSelector,
  featureFeeLogVmSelector,
  productPackagesLogVmSelector,
  driverUsersLogVmSelector,
  driversLogVmSelector,
  (features, fees, packages, driverUsers, drivers) =>
    [].concat(features, fees, packages, driverUsers, drivers).sort((a, b) => (a.date > b.date ? -1 : 1)),
);

export const systemLogWithUserNameSelector = createSelector(
  systemLogSortedSelector,
  firebaseUsersSelector,
  (logs, users) => mapSystemLogToUsers(logs, users),
);

export const totalRecordsSelector = createSelector(systemLogWithUserNameSelector, logs => logs.length);

export const pageSelector = createSelector(dashboardState, state => state.page);

export const pageSizeSelector = createSelector(pageSelector, page => page.pageSize);

export const pageIndexSelector = createSelector(pageSelector, page => page.pageIndex);

export const systemLogSelector = createSelector(systemLogWithUserNameSelector, pageSelector, (logs, page) =>
  logs.slice(page.pageIndex * page.pageSize, (page.pageIndex + 1) * page.pageSize),
);

export const systemLogLoadedSelector = createSelector(systemLogWithUserNameSelector, logs => logs.length !== 0);
