import { PlatformSetting } from '../../shared/models/platform-setting.model';
import { Driver, DriverAggregate } from '../../shared/models/driver.model';
import { DispatchTag } from '../../shared/models/dispatch-tag.model';
import { SystemLogVM } from '../../shared/models/dashboard.model.vm';
import { ProductFeature } from '../../shared/models/product-feature.model';
import { MetaOp } from '../../shared/models/entity.model';
import { FirebaseUser } from '../../shared/models/firebase-user.model';
import { ProductPackage } from '../../shared/models/product-package.model';
import { PricingFee, ProductType } from '../../shared/models/pricing-fee.model';
import { Area } from '../../shared/models/area.model';
import { AccountUser } from '../../../../../../src/app/shared/models/account-user.model';
import { DateTimeHelpers } from '../../../../../../src/app/shared/utils/dateTime.helpers';

export function getFeaturesLog(features: ProductFeature[]): SystemLogVM[] {
  const logs: SystemLogVM[] = [];
  const uniqueNames = [...new Set(features.map(s => s.id))];

  uniqueNames.forEach(id => {
    const sorted = features.filter(s => s.id === id).sort((a, b) => (a.meta_ts < b.meta_ts ? -1 : 1));

    sorted.forEach((feature, index) => {
      if (feature.meta_op === MetaOp.CREATE) {
        logs.push({
          html: 'Feature ' + styleNameValue(feature.name) + ' was created',
          date: DateTimeHelpers.numberOrTimestampToNumber(feature.meta_ts),
          metaIdentity: feature.meta_identity,
        });
      }

      if (feature.meta_op === MetaOp.UPDATE) {
        if (index >= 1) {
          const previous = sorted[index - 1];
          const current = feature;

          if (previous.count !== current.count) {
            logs.push({
              html:
                'Feature ' +
                fieldChangeFromTo(current.name, 'count', previous.count.toString(), current.count.toString()),
              date: DateTimeHelpers.numberOrTimestampToNumber(current.meta_ts),
              metaIdentity: current.meta_identity,
            });
          }

          if (previous.name !== current.name) {
            logs.push({
              html: 'Feature ' + fieldChangeFromTo(current.name, 'name', previous.name, current.name),
              date: DateTimeHelpers.numberOrTimestampToNumber(current.meta_ts),
              metaIdentity: current.meta_identity,
            });
          }

          if (previous.sort_order !== current.sort_order) {
            logs.push({
              html:
                'Feature ' +
                fieldChangeFromTo(
                  current.name,
                  'sort order',
                  previous.sort_order.toString(),
                  current.sort_order.toString(),
                ),
              date: DateTimeHelpers.numberOrTimestampToNumber(current.meta_ts),
              metaIdentity: current.meta_identity,
            });
          }
        }
      }
    });
  });

  return logs;
}

export function getFeatureFeeLog(
  fees: PricingFee[],
  activeTabId: string,
  features: ProductFeature[],
  areas: Area[],
): SystemLogVM[] {
  const logs: SystemLogVM[] = [];
  const uniqueFees = [
    ...new Set(
      fees.filter(d => d.area_id === activeTabId && d.product_type === ProductType.FEATURE).map(s => s.doc_id),
    ),
  ];

  uniqueFees.forEach(docId => {
    const sorted = fees.filter(s => s.doc_id === docId).sort((a, b) => (a.meta_ts < b.meta_ts ? -1 : 1));

    sorted.forEach((fee, index) => {
      if (fee.meta_op === MetaOp.CREATE) {
        const feature = features.find(f => f.id === fee.product_id);
        const area = areas.find(a => a.id === fee.area_id);
        logs.push({
          html:
            'Feature ' +
            styleNameValue(feature.name) +
            ' field ' +
            styleNameValue('fee') +
            ' in ' +
            styleNameValue(area.name) +
            ' was set to ' +
            styleToValue(fee.amount.display),
          date: DateTimeHelpers.numberOrTimestampToNumber(fee.meta_ts),
          metaIdentity: fee.meta_identity,
        });
      }

      if (fee.meta_op === MetaOp.UPDATE) {
        if (index >= 1) {
          const previous = sorted[index - 1];
          const current = fee;
          if (previous.amount.value !== current.amount.value) {
            const feature = features.find(f => f.id === fee.product_id);
            const area = areas.find(a => a.id === fee.area_id);
            logs.push({
              html:
                'Feature ' +
                styleNameValue(feature.name) +
                ' field ' +
                styleNameValue('fee') +
                ' in ' +
                styleNameValue(area.name) +
                ' was changed from ' +
                styleFromValue(previous.amount.display) +
                ' to ' +
                styleToValue(current.amount.display),
              date: DateTimeHelpers.numberOrTimestampToNumber(current.meta_ts),
              metaIdentity: current.meta_identity,
            });
          }
        }
      }
    });
  });
  return logs;
}

export function getPackagesLog(packages: ProductPackage[]): SystemLogVM[] {
  const logs: SystemLogVM[] = [];
  const uniqueNames = [...new Set(packages.map(s => s.id))];

  uniqueNames.forEach(id => {
    const sorted = packages.filter(s => s.id === id).sort((a, b) => (a.meta_ts < b.meta_ts ? -1 : 1));

    sorted.forEach((productPackage, index) => {
      if (productPackage.meta_op === MetaOp.CREATE) {
        logs.push({
          html: 'Package ' + styleNameValue(productPackage.name) + ' was created',
          date: DateTimeHelpers.numberOrTimestampToNumber(productPackage.meta_ts),
          metaIdentity: productPackage.meta_identity,
        });
      }

      if (productPackage.meta_op === MetaOp.UPDATE) {
        if (index >= 1) {
          const previous = sorted[index - 1];
          const current = productPackage;

          if (previous.enabled !== current.enabled) {
            logs.push({
              html:
                'Package ' +
                styleNameValue(current.name) +
                ' was ' +
                styleToValue(current.enabled ? 'enabled' : 'disabled'),
              date: DateTimeHelpers.numberOrTimestampToNumber(current.meta_ts),
              metaIdentity: current.meta_identity,
            });
          }
        }
      }
    });
  });

  return logs;
}

export function getPlatformSettingLog(platformSettings: PlatformSetting[]): SystemLogVM[] {
  const logs: SystemLogVM[] = [];

  const uniqueNames = [...new Set(platformSettings.map(s => s.name))];

  uniqueNames.forEach(name => {
    const sorted = platformSettings.filter(s => s.name === name).sort((a, b) => (a.meta_ts < b.meta_ts ? -1 : 1));

    if (sorted.length > 1) {
      sorted.forEach((setting, index) => {
        if (setting.meta_op === MetaOp.UPDATE) {
          if (index >= 1) {
            const current = setting;

            logs.push({
              html: 'Platform setting ' + styleNameValue(current.name) + 'was changed',
              date: DateTimeHelpers.numberOrTimestampToNumber(current.meta_ts),
              metaIdentity: current.meta_identity,
            });
          }
        }
      });
    }
  });

  return logs;
}

export function getDriverUsersLog(
  drivers: DriverAggregate[],
  activeTabId: string,
  accountUsers: AccountUser[],
): SystemLogVM[] {
  const logs: SystemLogVM[] = [];
  const uniqueDriverUsers = [...new Set(accountUsers.filter(u => u?.id).map(s => s.id))];
  uniqueDriverUsers.forEach(id => {
    const driverAggregate = drivers.find(d => d.doc_id === id);

    if (driverAggregate?.driver?.default_area_id !== activeTabId) {
      return;
    }

    const sorted = accountUsers.filter(s => s?.id === id).sort((a, b) => (a.meta_ts < b.meta_ts ? -1 : 1));

    sorted.forEach((driverUser, index) => {
      if (driverUser.meta_op === MetaOp.UPDATE) {
        if (index >= 1) {
          const previous = sorted[index - 1];
          const current = driverUser;

          if (previous.disabled !== current.disabled) {
            const driverName = driverAggregate.driver.first_name.concat(' ', driverAggregate.driver.last_name);
            logs.push({
              html:
                'Driver ' +
                styleNameValue(driverName) +
                ' was ' +
                styleToValue(current.disabled ? 'disabled' : 'enabled'),
              date: DateTimeHelpers.numberOrTimestampToNumber(current.meta_ts),
              metaIdentity: current.meta_identity,
            });
          }
        }
      }
    });
  });
  return logs;
}

export function getDriversLog(driversLog: Driver[], activeTabId: string, dispatchTags: DispatchTag[]): SystemLogVM[] {
  const logs: SystemLogVM[] = [];

  const uniqueDrivers = [...new Set(driversLog.filter(d => d.default_area_id === activeTabId).map(s => s.doc_id))];

  uniqueDrivers.forEach(id => {
    const sorted = driversLog.filter(s => s.doc_id === id).sort((a, b) => (a.meta_ts < b.meta_ts ? -1 : 1));

    if (sorted.length > 1) {
      sorted.forEach((driver, index) => {
        if (index >= 1) {
          const previous = sorted[index - 1];
          const current = driver;

          if (current.meta_op === MetaOp.UPDATE) {
            const diff = getDifference(previous.dispatch_tag_ids, current.dispatch_tag_ids);
            if (diff.changes > 0) {
              const driverName = current.first_name.concat(' ', current.last_name);

              let text = styleNameValue(driverName) + ' dispatch tag ';
              if (diff.removed.length > 0) {
                text += ' removed ';
                diff.removed
                  .map(dispatchTagId => dispatchTags.find(t => t.id === dispatchTagId)?.name)
                  .forEach(tag => (text += styleFromValue(tag)));
              }

              if (diff.added.length > 0) {
                text += ' added ';
                diff.added
                  .map(dispatchTagId => dispatchTags.find(t => t.id === dispatchTagId)?.name)
                  .forEach(tag => (text += styleToValue(tag)));
              }

              logs.push({
                html: 'Driver ' + text,
                date: DateTimeHelpers.numberOrTimestampToNumber(current.meta_ts),
                metaIdentity: current.meta_identity,
              });
            }
          }
        }
      });
    }
  });

  return logs;
}

function fieldChangeFromTo(name: string, field: string, from: string, to: string): string {
  return (
    styleNameValue(name) +
    ' field ' +
    styleNameValue(field) +
    ' was changed from ' +
    styleFromValue(from) +
    ' to ' +
    styleToValue(to) +
    ''
  );
}

function getDifference<T>(a: T[], b: T[]): { removed: T[]; added: T[]; changes: number } {
  const removed = a.filter(element => !b.includes(element));
  const added = b.filter(element => !a.includes(element));
  return {
    added,
    removed,
    changes: added.length + removed.length,
  };
}

function styleNameValue(name: string) {
  return '<span class="log-setting-name">' + name + '</span>';
}

function styleFromValue(from: string) {
  return '<span class="log-setting-value-from">' + from + '</span>';
}

function styleToValue(to: string) {
  return '<span class="log-setting-value-to">' + to + '</span>';
}

export function mapSystemLogToUsers(logs: SystemLogVM[], users: FirebaseUser[]): SystemLogVM[] {
  return logs.map(log => {
    const userEmail = users.find(u => u.id === log.metaIdentity)?.email;
    log.userName = userEmail ? userEmail : log.metaIdentity;
    return log;
  });
}
